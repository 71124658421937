var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { attrs: { id: "title" } }, [_vm._v("お知らせ一覧")]),
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col-12 col-lg-6" }, [
        !_vm.falseInfo
          ? _c(
              "div",
              { staticClass: "div text-right friend-request" },
              [
                _c("router-link", { attrs: { to: "/friend/friendRequest" } }, [
                  _c("small", [_vm._v("友達リクエストを確認する")])
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "spinner text-center" },
          [
            _vm.isLoading
              ? _c("b-spinner", {
                  staticClass: "mt-5 mb-5",
                  attrs: { variant: "primary", label: "Loading..." }
                })
              : _vm._e()
          ],
          1
        ),
        _vm.noticeList.length == 0
          ? _c("div", { staticClass: "div text-center no-data" }, [
              _vm._v(_vm._s(_vm.message)),
              _c("br"),
              _c(
                "div",
                { staticClass: "my-4" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("small", [_vm._v("TOPページに戻る")])
                  ])
                ],
                1
              )
            ])
          : _c(
              "div",
              { attrs: { id: "table" } },
              _vm._l(_vm.noticeList, function(item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "table-row",
                    class: { unread: !item.readFlg },
                    attrs: { value: item }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "delete text-right",
                        on: {
                          click: function($event) {
                            return _vm.deleteNotice(item)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "far fa-trash-alt" }),
                        _c("span", [_vm._v("削除")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "date",
                        on: {
                          click: function($event) {
                            return _vm.callRouterPush(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.created
                              .slice(0, 16)
                              .replace(/-/g, "/")
                              .replace(/T/g, " ")
                          )
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "notice",
                        on: {
                          click: function($event) {
                            return _vm.callRouterPush(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.noticeText))]
                    )
                  ]
                )
              }),
              0
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }