<template lang="pug">
  .container-fluid
    #title お知らせ一覧
    .row.justify-content-md-center
      .col-12.col-lg-6
        .div.text-right.friend-request(v-if="!falseInfo")
          router-link(to="/friend/friendRequest") <small>友達リクエストを確認する</small>
        .spinner.text-center
          b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
        .div.text-center.no-data(v-if="noticeList.length ==0")
          |{{message}}<br>
          div.my-4
            router-link(to="/") <small>TOPページに戻る</small>
        #table(v-else)
          .table-row(v-for="(item,index) in noticeList" :value="item" v-bind:class="{unread:!item.readFlg}")
            .delete.text-right(@click="deleteNotice(item)")
              i.far.fa-trash-alt
              //i.fas.fa-times
              span 削除
            .date(@click="callRouterPush(item)") {{item.created.slice(0, 16).replace(/-/g, '/').replace(/T/g, ' ')}}
            .notice(@click="callRouterPush(item)") {{item.noticeText}}

</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  props:{
  },
  data() {
    return {
      noticeList:[],
      message:'',
      isLoading: true,
      falseInfo: false,
    }
  },
  created(){
    //表示するデータ取得
    this.getNoticeList()
    //未読のお知らせを既読にデータ更新する
    this.updateUnreadNotice()
  },
  // メソッド
  methods: {
    //---------------------
    //お知らせ一覧を取得する
    //---------------------
    async getNoticeList() {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-notice'
      await axios
        .get(jsonUrl,{
          params: {
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          this.noticeList = response.data
          this.message = 'お知らせはありません'
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.falseInfo = true
          this.message = '情報の取得に失敗しました'
        })

    },
    //----------------------------
    //未読のお知らせを既読に更新する
    //----------------------------
    async updateUnreadNotice() {

      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-notice'
      let result = ""
      const params = {
      }
      await axios.patch(jsonUrl, params, {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.apiMessage = res.data.message
      })
      .catch(err => {
        //TODO:システムエラー
        // alert(err)
        this.isLoading = false
        this.falseInfo = true
        this.message = '情報の取得に失敗しました'
      })

      //App.vueのunReadNoticeを更新する。
      this.$parent.unReadNotice = ''

    },
    //----------------------------
    //お知らせを削除する
    //----------------------------
    async deleteNotice(item){

      //確認メッセージ
      const message = 'このお知らせを削除しますか？'
      const answer1 = '削除する'
      const answer2 = 'キャンセル'
      if(!await this.asyncDialogAnswer(message,answer1,answer2)){
        return
      }

      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-notice'
      await axios
        .delete(jsonUrl,{
          params: {
            'created': item.created
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          //表示するデータをリロード
          this.getNoticeList()
        })
        .catch(err => {
          this.isLoading = false
          this.falseInfo = true
          this.message = '情報の取得に失敗しました'
        })

    },
    //------------------------
    //確認ダイアログ
    //------------------------
    asyncDialogAnswer(message,answer1,answer2) {

      return new Promise((resolve) => {
        this.$bvModal.msgBoxConfirm(message, {
          title: '確認',
          okVariant: 'success',
          okTitle: answer1,
          cancelTitle: answer2,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
    callRouterPush(item){
      let path = ''
      //通知が友達リクエストの場合
      if(item.category=='FriendRequest'){
        path = '/friend/friendRequest'
      }      
      this.$router.push(path)
    },
  },
  // コンポーネント
  components: {
  }
}
</script>
<style lang="stylus" scoped>
.container-fluid
  padding-left 0px
  padding-right 0px
  #title
    padding 1.5rem
    text-align center
    font-size 1.5rem
    font-weight 600  
    color #606e79
  .row
    margin-right 0px
    margin-left 0px
    .col-12.col-lg-6
      padding 0
      .no-data
        padding 3rem 0 10rem 0
      #table
        padding 1rem 0 5rem 0
        .table-row
          display flex
          justify-content center
          align-items center
          background #eceeef
          color #374047
          font-size 0.8rem
          text-align left
          margin-bottom 2px
          height 5rem
          cursor pointer 
          position relative 
          .delete
            position absolute
            top 3px
            right 0.5rem
            color #98a1a8
            font-size 0.7rem
            i
              margin-right 3px
          .date
            width 25%
            height 100%
            padding 1.3rem 0.8rem
            display flex
            justify-content center
            align-items center
          .notice
            width 75%
            height 100%
            display flex
            justify-content center
            align-items center

        .table-row:hover
          background #606e79
          color #FFF
        //未読の行に背景色を付ける
        .unread
          background #f4c8cc
        .unread:hover
          background #ec9ea5

// 大デバイス（デスクトップ, 992px 以上）
@media (min-width 992px)
  .date
    margin-left 2rem
</style>